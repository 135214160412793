.carousel {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .carousel__image-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: top;
    }
  }

  .carousel__icon {
    color: $gray;
    position: absolute;
    font-size: 2.3rem;
    top: 50%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &.icon-left {
      left: 10px;
    }

    &.icon-right {
      right: 10px;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
}

.image-enter {
  opacity: 0;
}
.image-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
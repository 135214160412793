.header__nav-sm {
  width: 40%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  background-color: $primaryColor;

  @media (max-width: $breakbpoint-md) {
    width: 50%;
  }

  @media (max-width: $breakbpoint-sm) {
    width: 100%;
  }

  .header__icon {
    position: fixed;
    top: 1rem;
    right: 5%;
    font-size: 2rem;
  }

  .header__nav {
    width: 100%;
    height: 100%;
    padding: 7rem 1.5rem;

    .header__lists {
      flex-direction: column;
      font-size: 3rem;
      margin-bottom: 2rem;

      &::before,
      &::after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: $borderColor;
      }

      .header__list {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
          transform: translateX(15px);
        }

        &:hover span {
          scale: 1;
        }

        span {
          font-size: 0.7rem;
          padding-right: 2rem;
          scale: 0;
          transition: all 0.2s;
        }
      }
    }
  }
}

.header-enter {
  transform: translateX(100%);
}

.header-enter-active {
  transform: translateX(0);
  transition: all 500ms ease-in;
}

.header-exit {
  transform: translateX(0);
}

.header-exit-active {
  transform: translateX(100%);
  transition: all 500ms ease-in;
}

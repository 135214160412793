.contact {
  @include base-padding;

  .contact__wrapper {
    @include wrapper;
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    @media (max-width: $breakbpoint-md) {
      flex-direction: column;
    }
  }

  .contact__left {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (max-width: $breakbpoint-lg) {
      width: 50%;
    }

    @media (max-width: $breakbpoint-md) {
      width: 100%;
      align-items: center;
    }

    .contact__title {
      align-self: flex-start;
      font-size: 2.3rem;
      margin-left: 3rem;

      @media (max-width: $breakbpoint-lg) {
        margin-left: 0;
      }
    }

    .contact__project {
      font-size: 2.3rem;
      margin-top: 1rem;
      color: $gray;
      position: relative;
      text-align: end;

      @media (max-width: $breakbpoint-lg) {
        font-size: 1.8rem;
      }

      @media (max-width: $breakbpoint-md) {
        align-self: flex-end;
      }

      &::before {
        content: "";
        width: 4rem;
        height: 2px;
        background-color: $borderColor;
        position: absolute;
        top: 50%;
        left: -5rem;

        @media (max-width: 1200px) {
          display: none;
        }

        @media (max-width: $breakbpoint-md) {
          display: inline-block;
        }
      }
    }

    .contact__mail {
      margin: 3rem 0;
    }

    .contact__img-container {
      max-width: 345px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  form {
    width: 50%;
    display: flex;
    flex-direction: column;
    color: $secondaryColor;

    @media (max-width: $breakbpoint-md) {
      width: 100%;
    }

    .form__container {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 2rem;
      position: relative;

      input {
        @include input-reset;
        position: relative;

        &:not(:placeholder-shown) {
          + label {
            color: $borderColor;
            font-size: 0.9rem;
            transform: translateY(-5px);
          }
        }
        &:focus {
          animation: color-change 0.5s linear;

          + label {
            color: $borderColor;
            font-size: 0.9rem;
            transform: translateY(-5px);
          }

          + label::after {
            animation: width-change 2s linear;
          }
        }
      }

      label {
        transition: all 0.1s linear;
      }
    }

    textarea {
      @include input-reset;
      resize: none;
      font-family: inherit;

      &:not(:placeholder-shown) {
        + label {
          color: $borderColor;
          font-size: 0.9rem;
          transform: translateY(-5px);
        }
      }

      &:focus {
        animation: color-change 0.5s linear;

        + label {
          color: $borderColor;
          font-size: 0.9rem;
          transform: translateY(-5px);
        }
      }
    }

    .form__btn {
      padding: 0.8rem 1.5rem;
      align-self: flex-end;
      font-size: 1.2rem;
    }
  }
}

@keyframes color-change {
  0%,
  100% {
    border-color: $borderColor;
  }
  25% {
    border-color: $primaryColor;
  }
  75% {
    border-color: $primaryColor;
  }
}

.title {
    font-size: 2.3rem;
    margin-bottom: 2rem;
    position: relative;
    margin-left: 6rem;

    @media (max-width: $breakbpoint-sm) {
      margin-left: 4rem;
    }

    &::before {
      content: "";
      width: 5rem;
      height: 1px;
      background-color: $secondaryColor;
      position: absolute;
      top: 50%;
      left: -6rem;

      @media (max-width: $breakbpoint-sm) {
        width: 3rem;
        left: -4rem;
      }
    }

    &::after {
      content: "";
      width: 60%;
      height: 1px;
      background-color: $secondaryColor;
      position: absolute;
      top: 50%;
      margin-left: 1rem;

      @media (max-width: $breakbpoint-sm) {
        width: 35%;
      }
    }
  }
.main {
  height: 100vh;
  font-family: "Poppins", sans-serif;
  @include base-padding;

  .main__wrapper {
    @include wrapper;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;

    .main__title {
      font-size: 3rem;
      margin-bottom: 4rem;
      position: relative;

      @media (max-width: $breakbpoint-sm) {
        font-size: 2.2rem;
      }

      &::after {
        content: "";
        width: 10%;
        height: 1px;
        background-color: $secondaryColor;
        position: absolute;
        left: 0;
        bottom: -2rem;
      }
    }

    .main__description {
      font-size: 1.2rem;
      color: $gray;
      margin-bottom: 1rem;

      @media (max-width: $breakbpoint-sm) {
        font-size: 1rem;
      }
    }
  }
}

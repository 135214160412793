.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 80%;
    height: 100%;
    max-width: calc(100vw - 200px);
    max-height: calc(100vh - 60px);
    border: 1px solid $gray;
    backdrop-filter: blur(10px);
    border-radius: 20px;

    @media (max-width: $breakbpoint-lg) {
      width: 100%;
      max-width: 100%;
      max-height: 100%;
      background-color: $primaryColor;
    }

    .modal__top {
      position: relative;

      .modal__title {
        margin: 1rem 0;
        font-size: 2rem;
        text-align: center;
      }

      .modal__icon {
        font-size: 1.5rem;
        position: absolute;
        top: 35%;
        right: 2rem;
        cursor: pointer;
      }
    }

    .modal__main {
      min-height: 70%;
      display: flex;
      flex: 1;
      border-top: 1px solid $gray;
      border-bottom: 1px solid $gray;
      background-color: $primaryColor;

      @media (max-width: $breakbpoint-md) {
        flex-direction: column;
      }

      .modal__carousel {
        width: 75%;

        @media (max-width: $breakbpoint-md) {
          width: 100%;
        }
      }

      .modal__description {
        width: 25%;
        padding: 1rem;
        overflow: auto;
        border-left: 1px solid $gray;

        @media (max-width: $breakbpoint-md) {
          width: 100%;
        }

        .modal__icons {
          display: flex;
          justify-content: flex-end;
          gap: 1rem;
          margin-bottom: 1rem;
          font-size: 1.5rem;
        }

        p {
          line-height: 1.5rem;
        }

        .modal__skills {
          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem;
          margin-top: 1.2rem;

          .modal__skill {
            padding: 0.4rem 0.8rem;
            text-transform: uppercase;
            border: 1px solid $borderColor;
            border-radius: 30px;
            font-size: 0.8rem;
          }
        }
      }
    }

    .modal__bottom {
      display: flex;
      justify-content: space-between;
      padding: 1rem 2rem;

      .modal__prev {
        position: relative;
        cursor: pointer;
        transition: 0.5s;

        &::after {
          content: "«";
          font-size: 1.5rem;
          opacity: 0;
          position: absolute;
          bottom: -4px;
          left: -1.5rem;
          transition: 0.5s;
        }

        &:hover {
          padding-left: 0.5rem;
        }

        &:hover::after {
          opacity: 1;
          left: -0.5rem;
        }
      }

      .modal__next {
        position: relative;
        cursor: pointer;
        transition: 0.5s;

        &::after {
          content: "»";
          font-size: 1.5rem;
          opacity: 0;
          position: absolute;
          bottom: -4px;
          right: -1.5rem;
          transition: 0.5s;
        }

        &:hover {
          padding-right: 0.5rem;
        }

        &:hover::after {
          opacity: 1;
          right: -0.5rem;
        }
      }
    }
  }
}

.modal-enter {
  opacity: 0;
  transform: scale(0);
}

.modal-enter-active {
  backdrop-filter: blur(10px);
  opacity: 1;
  transform: scale(1);
  transition: all 300ms;
}

.modal-exit {
  opacity: 1;
  transform: scale(1);
}

.modal-exit-active {
  backdrop-filter: blur(10px);
  opacity: 0;
  transform: scale(0);
  transition: all 300ms;
}

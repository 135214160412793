.footer {
  @include base-padding;

  .footer__wrapper {
    @include wrapper;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-top: 2rem;

    &::after {
      content: "";
      width: 100%;
      height: 2px;
      background-color: $borderColor;
      position: absolute;
      top: -3rem;
      left: 0;
    }

    .footer__left {
      div {
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 0.5rem;
      }

      span {
        color: $gray;
        font-size: 0.9rem;
      }
    }

    .footer__links {
      display: flex;
      gap: 1.2rem;
      font-size: 2rem;
      color: $gray;

      .footer__link {
        transition: all 0.4s ease-in-out;

        &:hover {
          color: $secondaryColor;
        }
      }
    }
  }
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  position: absolute;
  top: 0;
  left: 0;
  color: $secondaryColor;
  background-color: transparent;

  .header__link {
    font-size: 1.5rem;
    font-weight: 700;
  }
  .header__nav {
    .header__lists {
      display: flex;
      gap: 2rem;
      position: relative;

      .header__list {
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          opacity: 0.6;
        }

        @media (max-width: $breakbpoint-sm) {
          display: none;
        }
      }
    }

    .header__menu {
      display: none;
      font-family: "Roboto", sans-serif;
      z-index: 99;
      font-size: 1.1rem;

      @media (max-width: $breakbpoint-sm) {
        display: block;
      }

      &.scrolled {
        display: block;
        position: fixed;
        top: 2rem;
        right: 1.5rem;
      }
    }
  }
}

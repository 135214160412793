$blue: #8685ef;
$rose: #ff00ff;
$green: #22a7b7;

.about {
  @include base-padding;

  .about__wrapper {
    @include wrapper;
    display: flex;
    flex-direction: column;
    align-items: center;

    .about__container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4rem;

      @media (max-width: $breakbpoint-lg) {
        gap: 3rem;
      }

      @media (max-width: $breakbpoint-md) {
        flex-direction: column;
      }

      .about__left {
        width: 70%;
        overflow: hidden;

        @media (max-width: $breakbpoint-lg) {
          width: 100%;
          overflow: visible;
        }

        .about__title {
          font-size: 2.3rem;
          margin-bottom: 2rem;
          position: relative;
          margin-left: 6rem;

          &::before {
            content: "";
            width: 5rem;
            height: 1px;
            background-color: $secondaryColor;
            position: absolute;
            top: 50%;
            left: -6rem;
          }

          &::after {
            content: "";
            width: 80%;
            height: 1px;
            background-color: $secondaryColor;
            position: absolute;
            top: 50%;
            margin-left: 1rem;
          }
        }

        .about__description {
          transition: all 0.5s ease-in;

          p {
            line-height: 1.6;
          }
        }

        .about__skills {
          width: 70%;
          display: flex;
          justify-content: space-between;
          margin-top: 3rem;
          transition: all 0.5s ease-in;

          @media (max-width: $breakbpoint-lg) {
            width: 100%;
          }

          @media (max-width: $breakbpoint-md) {
            width: 80%;
          }

          .about__skill {
            display: flex;
            flex-direction: column;

            span {
              margin-bottom: 1rem;
              position: relative;

              .about__tag {
                @include tag();

                &.frontend {
                  background-color: $blue;
                }

                &.backend {
                  background-color: $rose;
                }

                &.outil {
                  background-color: $green;
                }
              }

              &:hover .about__tag {
                width: 100%;
                animation: open 0.2s ease-in-out;
              }
            }
          }
        }
      }
    }
    .about__img-container {
      max-width: 320px;
      max-height: 425px;

      @media (max-width: $breakbpoint-md) {
        display: none;
      }

      img {
        width: 100%;
        height: auto;
        border-radius: 15px;
        background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" fill="%238685EF"><path d="M1912 1209c27-319-72-699-318-882-187-139-462-224-785-204S85 468 84 880c0 455 481 345 636 484s139 514 477 516c386 3 693-420 715-671Z"></path></svg>');
      }
    }
  }
}

@keyframes open {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes close {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}

.project {
  @include base-padding;

  .project__wrapper {
    @include wrapper;
  }

  .projects {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (max-width: $breakbpoint-lg) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0;
    }

    @media (max-width: $breakbpoint-sm) {
      flex-direction: column;
    }

    .project {
      display: flex;
      align-items: center;
      position: relative;
      padding: 3rem;
      cursor: pointer;

      @media (max-width: $breakbpoint-lg) {
        flex-direction: column;
        height: auto;
        width: 50%;
        padding: 0 0.8rem 8rem 0.8rem;
        align-items: flex-start;

        &:hover .project__img-sm {
          transform: scale(1.02);
        }
      }

      @media (max-width: $breakbpoint-md) {
        padding: 0 0.8rem 4rem 0.8rem;
      }

      @media (max-width: $breakbpoint-sm) {
        width: 100%;

        &:hover .project__img-sm {
          transform: none;
        }
      }

      &::after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: $borderColor;
        position: absolute;
        bottom: 0;
        left: 0;

        @media (max-width: $breakbpoint-lg) {
          display: none;
        }
      }

      &:hover {
        color: $grayHover;

        @media (max-width: $breakbpoint-lg) {
          color: $secondaryColor;
        }
      }

      &:hover .project__title {
        transform: translateX(-15px);

        @media (max-width: $breakbpoint-lg) {
          transform: none;
        }
      }

      &:hover .project__skill {
        transform: translateX(15px);

        @media (max-width: $breakbpoint-lg) {
          transform: none;
        }
      }

      .project__title {
        width: 75%;
        font-size: 3rem;
        padding-left: 4rem;
        transition: all 0.2s linear;

        @media (max-width: $breakbpoint-lg) {
          width: 100%;
          padding-left: 0;
          font-size: 2rem;
          margin-bottom: 2rem;
          position: relative;

          &::after {
            content: "";
            width: 100%;
            height: 1px;
            background-color: $borderColor;
            position: absolute;
            bottom: -5.5rem;
            left: 0;
          }
        }

        @media (max-width: $breakbpoint-sm) {
          font-size: 1.5rem;
          margin-bottom: 1rem;

          &::after {
            bottom: -4.5rem;
          }
        }
      }

      .project__skill {
        font-size: 1.5rem;
        transition: all 0.2s linear;

        @media (max-width: $breakbpoint-lg) {
          font-size: 1.2rem;
        }

        @media (max-width: $breakbpoint-sm) {
          font-size: 1rem;
        }
      }

      .project__img {
        width: 370px;
        position: fixed;
        pointer-events: none;
        z-index: 1;
        transition: left 200ms linear, top 200ms linear, opacity 200ms;
      }

      .project__img-container {
        width: 100%;
        margin-bottom: 2rem;
        overflow: hidden;

        .project__img-sm {
          width: 100%;
          height: auto;
          transition: all 0.5s;
        }
      }
    }
  }
}

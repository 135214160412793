@charset "UTF-8";
/* COLORS */
/* BREAKPOINTS */
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: inherit;
  scroll-behavior: smooth;
}
*:active, *:focus {
  outline: none;
}

body {
  height: 100vh;
  font-family: "Roboto", sans-serif;
  background-color: #181818;
  color: #ffffff;
  cursor: default;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.main {
  height: 100vh;
  font-family: "Poppins", sans-serif;
  padding: 4rem;
}
@media (max-width: 767px) {
  .main {
    padding: 3rem 2rem;
  }
}
.main .main__wrapper {
  max-width: 1400px;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
.main .main__wrapper .main__title {
  font-size: 3rem;
  margin-bottom: 4rem;
  position: relative;
}
@media (max-width: 599px) {
  .main .main__wrapper .main__title {
    font-size: 2.2rem;
  }
}
.main .main__wrapper .main__title::after {
  content: "";
  width: 10%;
  height: 1px;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  bottom: -2rem;
}
.main .main__wrapper .main__description {
  font-size: 1.2rem;
  color: #b1b1b1;
  margin-bottom: 1rem;
}
@media (max-width: 599px) {
  .main .main__wrapper .main__description {
    font-size: 1rem;
  }
}

.about {
  padding: 4rem;
}
@media (max-width: 767px) {
  .about {
    padding: 3rem 2rem;
  }
}
.about .about__wrapper {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about .about__wrapper .about__container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
}
@media (max-width: 991px) {
  .about .about__wrapper .about__container {
    gap: 3rem;
  }
}
@media (max-width: 767px) {
  .about .about__wrapper .about__container {
    flex-direction: column;
  }
}
.about .about__wrapper .about__container .about__left {
  width: 70%;
  overflow: hidden;
}
@media (max-width: 991px) {
  .about .about__wrapper .about__container .about__left {
    width: 100%;
    overflow: visible;
  }
}
.about .about__wrapper .about__container .about__left .about__title {
  font-size: 2.3rem;
  margin-bottom: 2rem;
  position: relative;
  margin-left: 6rem;
}
.about .about__wrapper .about__container .about__left .about__title::before {
  content: "";
  width: 5rem;
  height: 1px;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: -6rem;
}
.about .about__wrapper .about__container .about__left .about__title::after {
  content: "";
  width: 80%;
  height: 1px;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  margin-left: 1rem;
}
.about .about__wrapper .about__container .about__left .about__description {
  transition: all 0.5s ease-in;
}
.about .about__wrapper .about__container .about__left .about__description p {
  line-height: 1.6;
}
.about .about__wrapper .about__container .about__left .about__skills {
  width: 70%;
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  transition: all 0.5s ease-in;
}
@media (max-width: 991px) {
  .about .about__wrapper .about__container .about__left .about__skills {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .about .about__wrapper .about__container .about__left .about__skills {
    width: 80%;
  }
}
.about .about__wrapper .about__container .about__left .about__skills .about__skill {
  display: flex;
  flex-direction: column;
}
.about .about__wrapper .about__container .about__left .about__skills .about__skill span {
  margin-bottom: 1rem;
  position: relative;
}
.about .about__wrapper .about__container .about__left .about__skills .about__skill span .about__tag {
  width: 0;
  display: inline-block;
  position: absolute;
  top: -0.2rem;
  padding: 0.2rem 0;
  margin-left: 0.5rem;
  animation: close 0.2s ease-in-out;
  overflow: hidden;
  text-align: center;
  z-index: 1;
}
.about .about__wrapper .about__container .about__left .about__skills .about__skill span .about__tag.frontend {
  background-color: #8685ef;
}
.about .about__wrapper .about__container .about__left .about__skills .about__skill span .about__tag.backend {
  background-color: #ff00ff;
}
.about .about__wrapper .about__container .about__left .about__skills .about__skill span .about__tag.outil {
  background-color: #22a7b7;
}
.about .about__wrapper .about__container .about__left .about__skills .about__skill span:hover .about__tag {
  width: 100%;
  animation: open 0.2s ease-in-out;
}
.about .about__wrapper .about__img-container {
  max-width: 320px;
  max-height: 425px;
}
@media (max-width: 767px) {
  .about .about__wrapper .about__img-container {
    display: none;
  }
}
.about .about__wrapper .about__img-container img {
  width: 100%;
  height: auto;
  border-radius: 15px;
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" fill="%238685EF"><path d="M1912 1209c27-319-72-699-318-882-187-139-462-224-785-204S85 468 84 880c0 455 481 345 636 484s139 514 477 516c386 3 693-420 715-671Z"></path></svg>');
}

@keyframes open {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes close {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}
.project {
  padding: 4rem;
}
@media (max-width: 767px) {
  .project {
    padding: 3rem 2rem;
  }
}
.project .project__wrapper {
  max-width: 1400px;
  margin: 0 auto;
}
.project .projects {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media (max-width: 991px) {
  .project .projects {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0;
  }
}
@media (max-width: 599px) {
  .project .projects {
    flex-direction: column;
  }
}
.project .projects .project {
  display: flex;
  align-items: center;
  position: relative;
  padding: 3rem;
  cursor: pointer;
}
@media (max-width: 991px) {
  .project .projects .project {
    flex-direction: column;
    height: auto;
    width: 50%;
    padding: 0 0.8rem 8rem 0.8rem;
    align-items: flex-start;
  }
  .project .projects .project:hover .project__img-sm {
    transform: scale(1.02);
  }
}
@media (max-width: 767px) {
  .project .projects .project {
    padding: 0 0.8rem 4rem 0.8rem;
  }
}
@media (max-width: 599px) {
  .project .projects .project {
    width: 100%;
  }
  .project .projects .project:hover .project__img-sm {
    transform: none;
  }
}
.project .projects .project::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1647058824);
  position: absolute;
  bottom: 0;
  left: 0;
}
@media (max-width: 991px) {
  .project .projects .project::after {
    display: none;
  }
}
.project .projects .project:hover {
  color: rgba(255, 255, 255, 0.337254902);
}
@media (max-width: 991px) {
  .project .projects .project:hover {
    color: #ffffff;
  }
}
.project .projects .project:hover .project__title {
  transform: translateX(-15px);
}
@media (max-width: 991px) {
  .project .projects .project:hover .project__title {
    transform: none;
  }
}
.project .projects .project:hover .project__skill {
  transform: translateX(15px);
}
@media (max-width: 991px) {
  .project .projects .project:hover .project__skill {
    transform: none;
  }
}
.project .projects .project .project__title {
  width: 75%;
  font-size: 3rem;
  padding-left: 4rem;
  transition: all 0.2s linear;
}
@media (max-width: 991px) {
  .project .projects .project .project__title {
    width: 100%;
    padding-left: 0;
    font-size: 2rem;
    margin-bottom: 2rem;
    position: relative;
  }
  .project .projects .project .project__title::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.1647058824);
    position: absolute;
    bottom: -5.5rem;
    left: 0;
  }
}
@media (max-width: 599px) {
  .project .projects .project .project__title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .project .projects .project .project__title::after {
    bottom: -4.5rem;
  }
}
.project .projects .project .project__skill {
  font-size: 1.5rem;
  transition: all 0.2s linear;
}
@media (max-width: 991px) {
  .project .projects .project .project__skill {
    font-size: 1.2rem;
  }
}
@media (max-width: 599px) {
  .project .projects .project .project__skill {
    font-size: 1rem;
  }
}
.project .projects .project .project__img {
  width: 370px;
  position: fixed;
  pointer-events: none;
  z-index: 1;
  transition: left 200ms linear, top 200ms linear, opacity 200ms;
}
.project .projects .project .project__img-container {
  width: 100%;
  margin-bottom: 2rem;
  overflow: hidden;
}
.project .projects .project .project__img-container .project__img-sm {
  width: 100%;
  height: auto;
  transition: all 0.5s;
}

.contact {
  padding: 4rem;
}
@media (max-width: 767px) {
  .contact {
    padding: 3rem 2rem;
  }
}
.contact .contact__wrapper {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}
@media (max-width: 767px) {
  .contact .contact__wrapper {
    flex-direction: column;
  }
}
.contact .contact__left {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
@media (max-width: 991px) {
  .contact .contact__left {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .contact .contact__left {
    width: 100%;
    align-items: center;
  }
}
.contact .contact__left .contact__title {
  align-self: flex-start;
  font-size: 2.3rem;
  margin-left: 3rem;
}
@media (max-width: 991px) {
  .contact .contact__left .contact__title {
    margin-left: 0;
  }
}
.contact .contact__left .contact__project {
  font-size: 2.3rem;
  margin-top: 1rem;
  color: #b1b1b1;
  position: relative;
  text-align: end;
}
@media (max-width: 991px) {
  .contact .contact__left .contact__project {
    font-size: 1.8rem;
  }
}
@media (max-width: 767px) {
  .contact .contact__left .contact__project {
    align-self: flex-end;
  }
}
.contact .contact__left .contact__project::before {
  content: "";
  width: 4rem;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.1647058824);
  position: absolute;
  top: 50%;
  left: -5rem;
}
@media (max-width: 1200px) {
  .contact .contact__left .contact__project::before {
    display: none;
  }
}
@media (max-width: 767px) {
  .contact .contact__left .contact__project::before {
    display: inline-block;
  }
}
.contact .contact__left .contact__mail {
  margin: 3rem 0;
}
.contact .contact__left .contact__img-container {
  max-width: 345px;
}
.contact .contact__left .contact__img-container img {
  width: 100%;
  height: auto;
}
.contact form {
  width: 50%;
  display: flex;
  flex-direction: column;
  color: #ffffff;
}
@media (max-width: 767px) {
  .contact form {
    width: 100%;
  }
}
.contact form .form__container {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 2rem;
  position: relative;
}
.contact form .form__container input {
  background-color: transparent;
  border: none;
  padding-bottom: 0.5rem;
  margin-bottom: 2rem;
  outline: none;
  font-size: 1.2rem;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1647058824);
  position: relative;
}
.contact form .form__container input:not(:placeholder-shown) + label {
  color: rgba(255, 255, 255, 0.1647058824);
  font-size: 0.9rem;
  transform: translateY(-5px);
}
.contact form .form__container input:focus {
  animation: color-change 0.5s linear;
}
.contact form .form__container input:focus + label {
  color: rgba(255, 255, 255, 0.1647058824);
  font-size: 0.9rem;
  transform: translateY(-5px);
}
.contact form .form__container input:focus + label::after {
  animation: width-change 2s linear;
}
.contact form .form__container label {
  transition: all 0.1s linear;
}
.contact form textarea {
  background-color: transparent;
  border: none;
  padding-bottom: 0.5rem;
  margin-bottom: 2rem;
  outline: none;
  font-size: 1.2rem;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1647058824);
  resize: none;
  font-family: inherit;
}
.contact form textarea:not(:placeholder-shown) + label {
  color: rgba(255, 255, 255, 0.1647058824);
  font-size: 0.9rem;
  transform: translateY(-5px);
}
.contact form textarea:focus {
  animation: color-change 0.5s linear;
}
.contact form textarea:focus + label {
  color: rgba(255, 255, 255, 0.1647058824);
  font-size: 0.9rem;
  transform: translateY(-5px);
}
.contact form .form__btn {
  padding: 0.8rem 1.5rem;
  align-self: flex-end;
  font-size: 1.2rem;
}

@keyframes color-change {
  0%, 100% {
    border-color: rgba(255, 255, 255, 0.1647058824);
  }
  25% {
    border-color: #181818;
  }
  75% {
    border-color: #181818;
  }
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff;
  background-color: transparent;
}
.header .header__link {
  font-size: 1.5rem;
  font-weight: 700;
}
.header .header__nav .header__lists {
  display: flex;
  gap: 2rem;
  position: relative;
}
.header .header__nav .header__lists .header__list {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.header .header__nav .header__lists .header__list:hover {
  opacity: 0.6;
}
@media (max-width: 599px) {
  .header .header__nav .header__lists .header__list {
    display: none;
  }
}
.header .header__nav .header__menu {
  display: none;
  font-family: "Roboto", sans-serif;
  z-index: 99;
  font-size: 1.1rem;
}
@media (max-width: 599px) {
  .header .header__nav .header__menu {
    display: block;
  }
}
.header .header__nav .header__menu.scrolled {
  display: block;
  position: fixed;
  top: 2rem;
  right: 1.5rem;
}

.footer {
  padding: 4rem;
}
@media (max-width: 767px) {
  .footer {
    padding: 3rem 2rem;
  }
}
.footer .footer__wrapper {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 2rem;
}
.footer .footer__wrapper::after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.1647058824);
  position: absolute;
  top: -3rem;
  left: 0;
}
.footer .footer__wrapper .footer__left div {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.footer .footer__wrapper .footer__left span {
  color: #b1b1b1;
  font-size: 0.9rem;
}
.footer .footer__wrapper .footer__links {
  display: flex;
  gap: 1.2rem;
  font-size: 2rem;
  color: #b1b1b1;
}
.footer .footer__wrapper .footer__links .footer__link {
  transition: all 0.4s ease-in-out;
}
.footer .footer__wrapper .footer__links .footer__link:hover {
  color: #ffffff;
}

.title {
  font-size: 2.3rem;
  margin-bottom: 2rem;
  position: relative;
  margin-left: 6rem;
}
@media (max-width: 599px) {
  .title {
    margin-left: 4rem;
  }
}
.title::before {
  content: "";
  width: 5rem;
  height: 1px;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: -6rem;
}
@media (max-width: 599px) {
  .title::before {
    width: 3rem;
    left: -4rem;
  }
}
.title::after {
  content: "";
  width: 60%;
  height: 1px;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  margin-left: 1rem;
}
@media (max-width: 599px) {
  .title::after {
    width: 35%;
  }
}

.shapes .shape {
  position: absolute;
  z-index: -1;
}
.shapes .shape.circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 3px solid;
  top: 100px;
  right: 100px;
  animation: 16s linear infinite;
}
.shapes .shape.block {
  width: 25px;
  height: 25px;
  border: 3px solid;
  top: 0px;
  left: 0px;
  animation: 16s linear infinite;
}
.shapes .shape.triangle {
  height: 3px;
  width: 28px;
  bottom: 50%;
  left: 50%;
  animation: 16s linear infinite;
}
.shapes .shape.triangle::before, .shapes .shape.triangle::after {
  content: "";
  position: absolute;
  height: 3px;
  width: 27px;
  bottom: 11px;
  background-color: inherit;
}
.shapes .shape.triangle::before {
  right: 7px;
  transform: rotate(122deg);
}
.shapes .shape.triangle::after {
  left: 7px;
  transform: rotate(58deg);
}

/* ANIMATIONS */
@keyframes squiggle-one {
  0% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(150px, 100px);
  }
  50% {
    transform: translate(240px, 300px);
  }
  75% {
    transform: translate(200px, 50px);
  }
}
@keyframes squiggle-two {
  0% {
    transform: translate(0px, 200px);
  }
  25% {
    transform: translate(-60px, 100px);
  }
  75% {
    transform: translate(60px, 0px);
  }
}
@keyframes squiggle-three {
  0% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(20px, 200px);
  }
  50% {
    transform: translate(-136.3636363636px, -100px);
  }
  75% {
    transform: translate(-150px, 30px);
  }
}
.button {
  font-family: inherit;
  background-color: transparent;
  padding: 1rem 1.5rem;
  border: solid 1px transparent;
  cursor: pointer;
  position: relative;
}
.button:active {
  transform: translateY(3px);
}
.button::before, .button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  transition: all 0.3s;
}
.button::before {
  border: solid 1px rgba(255, 255, 255, 0.1647058824);
}
.button:hover::before {
  opacity: 0;
}
.button::after {
  border: solid 1px #e6e6e6;
  opacity: 0;
  transform: scale(1.1, 1.3);
}
.button:hover::after {
  opacity: 1;
  transform: scale(1, 1);
}

.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal .modal__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  height: 100%;
  max-width: calc(100vw - 200px);
  max-height: calc(100vh - 60px);
  border: 1px solid #b1b1b1;
  backdrop-filter: blur(10px);
  border-radius: 20px;
}
@media (max-width: 991px) {
  .modal .modal__wrapper {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    background-color: #181818;
  }
}
.modal .modal__wrapper .modal__top {
  position: relative;
}
.modal .modal__wrapper .modal__top .modal__title {
  margin: 1rem 0;
  font-size: 2rem;
  text-align: center;
}
.modal .modal__wrapper .modal__top .modal__icon {
  font-size: 1.5rem;
  position: absolute;
  top: 35%;
  right: 2rem;
  cursor: pointer;
}
.modal .modal__wrapper .modal__main {
  min-height: 70%;
  display: flex;
  flex: 1;
  border-top: 1px solid #b1b1b1;
  border-bottom: 1px solid #b1b1b1;
  background-color: #181818;
}
@media (max-width: 767px) {
  .modal .modal__wrapper .modal__main {
    flex-direction: column;
  }
}
.modal .modal__wrapper .modal__main .modal__carousel {
  width: 75%;
}
@media (max-width: 767px) {
  .modal .modal__wrapper .modal__main .modal__carousel {
    width: 100%;
  }
}
.modal .modal__wrapper .modal__main .modal__description {
  width: 25%;
  padding: 1rem;
  overflow: auto;
  border-left: 1px solid #b1b1b1;
}
@media (max-width: 767px) {
  .modal .modal__wrapper .modal__main .modal__description {
    width: 100%;
  }
}
.modal .modal__wrapper .modal__main .modal__description .modal__icons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}
.modal .modal__wrapper .modal__main .modal__description p {
  line-height: 1.5rem;
}
.modal .modal__wrapper .modal__main .modal__description .modal__skills {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1.2rem;
}
.modal .modal__wrapper .modal__main .modal__description .modal__skills .modal__skill {
  padding: 0.4rem 0.8rem;
  text-transform: uppercase;
  border: 1px solid rgba(255, 255, 255, 0.1647058824);
  border-radius: 30px;
  font-size: 0.8rem;
}
.modal .modal__wrapper .modal__bottom {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
}
.modal .modal__wrapper .modal__bottom .modal__prev {
  position: relative;
  cursor: pointer;
  transition: 0.5s;
}
.modal .modal__wrapper .modal__bottom .modal__prev::after {
  content: "«";
  font-size: 1.5rem;
  opacity: 0;
  position: absolute;
  bottom: -4px;
  left: -1.5rem;
  transition: 0.5s;
}
.modal .modal__wrapper .modal__bottom .modal__prev:hover {
  padding-left: 0.5rem;
}
.modal .modal__wrapper .modal__bottom .modal__prev:hover::after {
  opacity: 1;
  left: -0.5rem;
}
.modal .modal__wrapper .modal__bottom .modal__next {
  position: relative;
  cursor: pointer;
  transition: 0.5s;
}
.modal .modal__wrapper .modal__bottom .modal__next::after {
  content: "»";
  font-size: 1.5rem;
  opacity: 0;
  position: absolute;
  bottom: -4px;
  right: -1.5rem;
  transition: 0.5s;
}
.modal .modal__wrapper .modal__bottom .modal__next:hover {
  padding-right: 0.5rem;
}
.modal .modal__wrapper .modal__bottom .modal__next:hover::after {
  opacity: 1;
  right: -0.5rem;
}

.modal-enter {
  opacity: 0;
  transform: scale(0);
}

.modal-enter-active {
  backdrop-filter: blur(10px);
  opacity: 1;
  transform: scale(1);
  transition: all 300ms;
}

.modal-exit {
  opacity: 1;
  transform: scale(1);
}

.modal-exit-active {
  backdrop-filter: blur(10px);
  opacity: 0;
  transform: scale(0);
  transition: all 300ms;
}

.carousel {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.carousel .carousel__image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel .carousel__image-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: top;
}
.carousel .carousel__icon {
  color: #b1b1b1;
  position: absolute;
  font-size: 2.3rem;
  top: 50%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.carousel .carousel__icon.icon-left {
  left: 10px;
}
.carousel .carousel__icon.icon-right {
  right: 10px;
}
.carousel .carousel__icon:hover {
  transform: scale(1.1);
}

.image-enter {
  opacity: 0;
}

.image-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.header__nav-sm {
  width: 40%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  background-color: #181818;
}
@media (max-width: 767px) {
  .header__nav-sm {
    width: 50%;
  }
}
@media (max-width: 599px) {
  .header__nav-sm {
    width: 100%;
  }
}
.header__nav-sm .header__icon {
  position: fixed;
  top: 1rem;
  right: 5%;
  font-size: 2rem;
}
.header__nav-sm .header__nav {
  width: 100%;
  height: 100%;
  padding: 7rem 1.5rem;
}
.header__nav-sm .header__nav .header__lists {
  flex-direction: column;
  font-size: 3rem;
  margin-bottom: 2rem;
}
.header__nav-sm .header__nav .header__lists::before, .header__nav-sm .header__nav .header__lists::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1647058824);
}
.header__nav-sm .header__nav .header__lists .header__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__nav-sm .header__nav .header__lists .header__list:hover {
  transform: translateX(15px);
}
.header__nav-sm .header__nav .header__lists .header__list:hover span {
  scale: 1;
}
.header__nav-sm .header__nav .header__lists .header__list span {
  font-size: 0.7rem;
  padding-right: 2rem;
  scale: 0;
  transition: all 0.2s;
}

.header-enter {
  transform: translateX(100%);
}

.header-enter-active {
  transform: translateX(0);
  transition: all 500ms ease-in;
}

.header-exit {
  transform: translateX(0);
}

.header-exit-active {
  transform: translateX(100%);
  transition: all 500ms ease-in;
}

.social__links {
  display: flex;
  gap: 1.2rem;
  font-size: 2rem;
  color: #b1b1b1;
}
.social__links .social__link {
  transition: all 0.4s ease-in-out;
}
.social__links .social__link:hover {
  color: #ffffff;
}



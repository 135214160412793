@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: inherit;
  scroll-behavior: smooth;

  &:active,
  &:focus {
    outline: none;
  }
}

body {
  height: 100vh;
  font-family: "Roboto", sans-serif;
  background-color: $primaryColor;
  color: $secondaryColor;
  cursor: default;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}
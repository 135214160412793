/* COLORS */
$primaryColor: #181818;
$secondaryColor: #ffffff;
$gray: #b1b1b1;
$grayHover: #ffffff56;
$borderColor: #ffffff2a;

/* BREAKPOINTS */
$breakbpoint-sm: 599px;
$breakbpoint-md: 767px;
$breakbpoint-lg: 991px;

@mixin base-padding {
  padding: 4rem;

  @media (max-width: $breakbpoint-md) {
    padding: 3rem 2rem;
  }
}

@mixin wrapper {
  max-width: 1400px;
  margin: 0 auto;
}

@mixin tag {
  width: 0;
  display: inline-block;
  position: absolute;
  top: -0.2rem;
  padding: 0.2rem 0;
  margin-left: 0.5rem;
  animation: close 0.2s ease-in-out;
  overflow: hidden;
  text-align: center;
  z-index: 1;
}

@mixin input-reset {
  background-color: transparent;
  border: none;
  padding-bottom: 0.5rem;
  margin-bottom: 2rem;
  outline: none;
  font-size: 1.2rem;
  border-bottom: solid 1px #ffffff2a;
}
